import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useErrorHandler } from 'react-error-boundary';
import { withApiConfigs } from '../services/ApiConfigsContextProvider';
import PropTypes from 'prop-types';

const JourneyTimeout = ({ apiConfigs }) => {
  const handleError = useErrorHandler();
  var timeout = 24 * 1000 * 60 * 60;

  if (apiConfigs.timeoutLifeTimeJourney) {
    timeout = apiConfigs.timeoutLifeTimeJourney * 1000 * 60 * 60;
  }
  const [, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });
  useEffect(() => {
    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, [getRemainingTime, handleError]);

  if (isIdle) {
    throw new Error('JourneyTimeoutMessage');
  }
  return <></>;
};
JourneyTimeout.propTypes = {
  apiConfigs: PropTypes.any,
};
export default withApiConfigs(JourneyTimeout);
