import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import GlobalThemeProvider from './themes/GlobalThemeProvider';
import LoadingSuspense from './components/LoadingSuspense';
import RouteWrapper from './components/RouteWrapper';
import TenantPermissions from './enums/TenantPermissions';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { Typo } from 'ri-components';
import { Box } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';
import { FetcherProvider, useQueryFetcher } from './hooks/useFetcher';
import { withTracking } from './services/AppInsightsProvider';
import LineOfBusinessEnum from './enums/LineOfBusinessEnum';
import { ReactQueryDevtools } from 'react-query/devtools';
import Unauthorized from './pages/unauthorized/Unauthorized';
import ContinueErrorFallback from './components/ContinueErrorFallback';
import JourneyTimeout from './components/JourneyTimeOut';
const RightIndemWebChat = React.lazy(() => import('./pages/web-chat/RightIndemWebChat'));
const ContinueWebChat = React.lazy(() => import('./pages/web-chat/ContinueWebChat'));

const App = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
  return (
    <Switch>
      <RouteWrapper exact path='/continue/:id/:emailExpiryId'>
        <ErrorBoundary FallbackComponent={ContinueErrorFallback}>
          <ContinueWebChat />
        </ErrorBoundary>
      </RouteWrapper>
      <RouteWrapper exact path='/' permission={TenantPermissions.Home}>
        <RightIndemWebChat lineOfBusiness={LineOfBusinessEnum.Home} />
      </RouteWrapper>
      <RouteWrapper exact path='/motor' permission={TenantPermissions.Motor}>
        <RightIndemWebChat lineOfBusiness={LineOfBusinessEnum.Motor} />
      </RouteWrapper>
      <RouteWrapper exact path='/auth-motor/:userIdentifier?' permission={TenantPermissions.Motor}>
        <RightIndemWebChat lineOfBusiness={LineOfBusinessEnum.Motor} isAuthJourney={true} />
      </RouteWrapper>
      <RouteWrapper
        exact
        path='/witness/:originalConversationId/:witnessIdentifier'
        permission={TenantPermissions.Motor}
      >
        <ErrorBoundary>
          <RightIndemWebChat lineOfBusiness={LineOfBusinessEnum.Motor} />
        </ErrorBoundary>
      </RouteWrapper>
      <Route exact path='/unauthorized'>
        <Unauthorized />
      </Route>
      <Route>
        <Box textAlign='center'>
          <Typo variant='title2'>404 - Page does not exists</Typo>
        </Box>
      </Route>
    </Switch>
  );
};

function AppWrapper() {

  return (
    <GlobalThemeProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LoadingSuspense>
          <FetcherProvider>
            <QueryClientProviderWrapper>
              <JourneyTimeout />
              <App />
            </QueryClientProviderWrapper>
          </FetcherProvider>
        </LoadingSuspense>
      </ErrorBoundary>
    </GlobalThemeProvider>
  );
}
const QueryClientProviderWrapper = React.memo(({ children }) => {
  const fetcher = useQueryFetcher();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            queryFn: (ctx) => fetcher(ctx.queryKey[0]),
            refetchOnWindowFocus: false,
          },
        },
      }),
    [fetcher],
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
});

QueryClientProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTracking(AppWrapper);
