import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { colorPalette } from 'ri-components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { isDev } from '../utils/isDev';

const { grey } = colorPalette;

const useStyles = makeStyles({
  errorContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '17px',
    color: grey[10],
  },
});

function ContinueErrorFallback({ error }) {
  const { errorContainer } = useStyles();

  const fullLanguage = new URLSearchParams(window.location.search).get('lang') ?? 'en';
  const language = fullLanguage.indexOf('-') == -1 ? fullLanguage : fullLanguage.substring(0, fullLanguage.indexOf('-'));

  var errorDictionary = {
    'Request failed with status code 408': {
      'en': (
        <>
          I&apos;m sorry, looks like your claim session has expired
          <br />
          Please start again
        </>
      ),
      'nl': (
        <>
          Sorry, de link die je hebt gebruikt is niet langer actief.
          <br />
          Je kunt een nieuwe schademelding starten
        </>
      ),
    },

    'Request failed with status code 409': {
      'en': (
        <>
          It looks like you&apos;ve already submitted your claim, you should receive your claim confirmation
          email shortly
        </>
      ),
      'nl': (
        <>
          Je hebt je schademelding al verstuurd. Je krijgt een bevestiging hiervan via je e-mailadres 
        </>
      ),
    },
  };

  if (errorDictionary[error.message] === undefined
    || errorDictionary[error.message][language] === undefined) {
    throw error;
  }

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (appInsights && appInsights.core) {
      appInsights.trackException({
        exception: new Error(error),
        severityLevel: SeverityLevel.Error,
      });
    } else if (!isDev()) {
      console.warn('Insights are not connected');
    }
  }, [appInsights, error]);
  
  return (
    <div role='alert' className={errorContainer}>
      <p>
        {errorDictionary[error.message][language]}
      </p>
    </div>
  );
}

ContinueErrorFallback.propTypes = {
  error: PropTypes.any,
  resetErrorBoundary: PropTypes.func,
};

export default ContinueErrorFallback;
